import React, {
  createContext,
  useRef,
  PropsWithChildren,
  useMemo,
  MutableRefObject,
  useState,
} from 'react';
import mapboxgl from 'mapbox-gl';

interface TimelineMapContextType {
  refTimelineMap: MutableRefObject<mapboxgl.Map | null>;
  mapReady: boolean;
  setMapReady: (ready: boolean) => void;
  refStyleReady: MutableRefObject<boolean>;
}

export const TimelineMapContext = createContext<TimelineMapContextType | null>(
  null,
);

export function TimelineMapContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const refTimelineMap = useRef<mapboxgl.Map>(null);
  const [mapReady, setMapReady] = useState(false);
  const refStyleReady = useRef(false);

  const contextValue = useMemo(
    () => ({ refTimelineMap, mapReady, setMapReady, refStyleReady }),
    [refTimelineMap, mapReady, refStyleReady],
  );

  return (
    <TimelineMapContext.Provider value={contextValue}>
      {children}
    </TimelineMapContext.Provider>
  );
}
