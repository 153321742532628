import { RouteInfo } from 'appTypes';
import { isArray } from 'highcharts';
import { SavedRoute, useGetSavedRouteQuery } from 'state/savedRouteSlices';
import { useSelector } from 'react-redux';
import { selectProject, selectRouteSelection } from 'state/workflowSlice';
import { useContext } from 'react';
import { LayerContext } from 'state/LayerContext';
import { useGetLayerQuery, useGetRouteInfoQuery } from 'state/apiSlice';
import { getRouteForSparseSegments } from 'features/map/mapUtils';

export function routeSelectionIsSavedRoute(routeSelection) {
  return !isArray(routeSelection);
}

export type BasicRouteInfo = {
  isSavedRoute: boolean;
  realizedSegments: string[];
  waypointSegments: string[];
};

export function useRouteInfo(skip: boolean = false):
  | {
      feature: RouteInfo;
      savedRoute?: SavedRoute;
      route?: any;
      basicRouteInfo: BasicRouteInfo;
    }
  | undefined {
  const project_slug = useSelector(selectProject);
  const routeSelection = useSelector(selectRouteSelection);
  const isSavedRoute = routeSelectionIsSavedRoute(routeSelection);
  const { layer } = useContext(LayerContext);

  const { currentData: layerData } = useGetLayerQuery(layer, {
    skip: skip || !layer || !routeSelection,
  });

  const { currentData: savedRouteData } = useGetSavedRouteQuery(
    { project_slug, savedRouteId: routeSelection, layerId: layer },
    { skip: skip || !isSavedRoute || !routeSelection },
  );

  let realizedSegments = savedRouteData?.savedRoute?.realizedSegments;
  let waypointSegments = savedRouteData?.savedRoute?.routeSegmentIds;
  if (!isSavedRoute) {
    waypointSegments = routeSelection;
    if (layerData && routeSelection) {
      realizedSegments = getRouteForSparseSegments(
        layerData.routing,
        routeSelection,
      );
    }
  }

  const basicRouteInfo = {
    isSavedRoute,
    realizedSegments,
    waypointSegments,
  };

  const { currentData: routeData } = useGetRouteInfoQuery(
    { project_slug, segment_ids: realizedSegments, layer_id: layer },
    { skip: skip || isSavedRoute || !realizedSegments },
  );

  if (isSavedRoute) {
    return {
      feature: savedRouteData?.feature,
      savedRoute: savedRouteData?.savedRoute,
      basicRouteInfo,
    };
  } else {
    return {
      feature: routeData?.feature,
      route: routeData?.route,
      basicRouteInfo,
    };
  }
}
