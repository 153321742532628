import React from 'react';
import styles from 'features/map/Map.module.css';
// https://svg2jsx.com/
import direction_arrow from 'features/map/direction_arrow_sdf.png';
import direction_arrow_half_lhd from 'features/map/half_direction_arrow_sdf_lhd.png';
import direction_arrow_half_rhd from 'features/map/half_direction_arrow_sdf_rhd.png';
import bookmark_marker from 'features/map/bookmark_marker_sdf.png';
import traffic from 'features/map/traffic.png';
import closed from 'features/map/closed.png';
import roadworks from 'features/map/roadworks.png';
import default_ from 'features/map/default.png';

import { kSelectedRouteLayerDefinition } from './mapCommon';

export const kDirectionArrowName = 'direction_arrow_selected';
export const kHalfDirectionArrowNameLHD = 'direction_half_arrow_l';
export const kHalfDirectionArrowNameRHD = 'direction_half_arrow_r';
export const kDirectionArrowNameRoute = 'direction_arrow_route';
export const kBookmarkMarkerImg = 'bookmark_marker';
export const kRoadworksIconImg = 'roadworks';
export const kTrafficIconImg = 'traffic';
export const kAccidentIconImg = 'accident';
export const kClosureIconImg = 'closure';
export const kClosurePartialIconImg = 'closure_partial';
export const kDefaultIconImg = 'default';

export function MapIconProvider() {
  return (
    <div className={styles.routeDirectionArrowContainer}>
      <img
        id={kDirectionArrowName}
        width="100"
        height="100"
        src={direction_arrow}
        alt=""
      />
      <img
        id={kHalfDirectionArrowNameLHD}
        width="100"
        height="100"
        src={direction_arrow_half_lhd}
        alt=""
      />
      <img
        id={kHalfDirectionArrowNameRHD}
        width="100"
        height="100"
        src={direction_arrow_half_rhd}
        alt=""
      />
      <img
        id={kBookmarkMarkerImg}
        width="50"
        height="50"
        src={bookmark_marker}
        alt=""
      />
      <img
        id={kRoadworksIconImg}
        className="hidden-ico"
        height="30px"
        width="30px"
        alt=""
        src={roadworks}
      />
      <img
        id={kTrafficIconImg}
        className="hidden-ico"
        height="30px"
        width="30px"
        alt=""
        src={traffic}
      />
      <img
        id={kAccidentIconImg}
        className="hidden-ico"
        height="30px"
        width="30px"
        alt=""
        src={traffic}
      />
      <img
        id={kClosureIconImg}
        className="hidden-ico"
        height="30px"
        width="30px"
        alt=""
        src={closed}
      />
      <img
        id={kClosurePartialIconImg}
        className="hidden-ico"
        height="30px"
        width="30px"
        alt=""
        src={closed}
      />
      <img
        id={kDefaultIconImg}
        className="hidden-ico"
        height="30px"
        width="30px"
        alt=""
        src={default_}
      />

      <svg
        id={kDirectionArrowNameRoute}
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        version="1.1"
        viewBox="0 0 13.229 13.229"
      >
        <g>
          <path
            fill={kSelectedRouteLayerDefinition.paint['line-color']}
            d="M6.621 2.637L.043 9.432l1.205 1.166 5.373-5.55 5.375 5.55 1.205-1.166z"
            color="#000"
          />
        </g>
      </svg>
    </div>
  );
}
