import { createSlice } from '@reduxjs/toolkit';

import { replaceOrPushURLParam, resetURLParams } from 'appUtils';

import {
  BIN_15_MINS,
  BIN_60_MINS,
  MapStyle,
  MAXIMIZE_DATA,
  MAXIMIZE_MAP,
  VIZ_MODE_CHART,
  VIZ_MODE_TABLE,
  WORKFLOWS_ALL_ENABLED,
  WorkflowType,
} from 'appConstants';
import { workflowLookup } from 'features/workflow_common/workflowLookup';
import { routeSelectionIsSavedRoute } from 'features/common/useRouteInfo';

import { getProjectInfoForSlug, selectUserState } from './userSlice';

const kProjectParam = 'project';
const kDateParam = 'date';
const kWorkflowParam = 'workflow';
const kSelectedSegmentParam = 'segmentId';
const kRouteSelectedParam = 'route';
const kMapLatParam = 'lat';
const kMapLngParam = 'lng';
const kMapZoomParam = 'zoom';
const kTypicalMonthParam = 'typic';
const kComparisonParam = 'comp';
const kSlowdownThresholdParam = 'threshold';
const kMaximizeParam = 'max';
const kBinSizeParam = 'bin';
const kCapacityThresholdParam = 'capacity';
const kCapacityLanesParam = 'lanes';
const kVizModeParam = 'viz';
const kTimeOfDayParam = 'tod';
const kTimelineMetricParam = 'metric';

const kDefaultWorkflow = undefined;
const kDefaultSlowdownThreshold = 0.65;
export const kDefaultCapacityThreshold = 600;
export const kDefaultCapacityLanes = 2;
export const kDefaultMapStyle = MapStyle.Dark;
const kMaxRouteSelectionHistory = 20;

const initialWorkflowState = {
  targetDate: null,
  segmentId: undefined,
  routeSelection: undefined,
  typicalMonth: null,
  maximize: null,
};

const initialSlowdownState = {
  slowdownThreshold: kDefaultSlowdownThreshold,
  comparison: undefined,
};

const initialWorkflowTravelTimeToolbarState = {
  workflowTravelTimeToolbarUIState: undefined,
  routeSelectionHistory: [undefined],
};

const initialPlanningState = {
  binSize: null,
  capacityThreshold: kDefaultCapacityThreshold,
  capacityLanes: kDefaultCapacityLanes,
  vizMode: null,
};

const initialTimelinestate = {
  timeOfDay: 12 * 60,
  timelineMetric: undefined,
  showIncidents: false,
};

const workflowsInitialState = {
  workflows: {
    [WorkflowType.Plan]: { ...initialWorkflowState, ...initialPlanningState },
    [WorkflowType.TravelTime]: {
      ...initialWorkflowState,
      ...initialSlowdownState,
      ...initialWorkflowTravelTimeToolbarState,
    },
    [WorkflowType.Speed]: { ...initialWorkflowState, ...initialSlowdownState },
    [WorkflowType.Timeline]: {
      ...initialWorkflowState,
      ...initialTimelinestate,
    },
  },
  mapCenter: null,
  mapZoom: null,
  mapStyle: kDefaultMapStyle,
};

const initialState = {
  current: kDefaultWorkflow,
  project_slug: undefined,
  projects: {},
};

function setUrlFromState(state, wipe = false) {
  let searchParams;
  if (wipe) {
    searchParams = new URLSearchParams();
  } else {
    searchParams = new URLSearchParams(window.location.search);
  }
  function setParam(key, value) {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  }

  // console.log('setUrlFromState', state);
  const project = state.project_slug;
  if (project) {
    setParam(kProjectParam, project);
    if (!state.projects[project]) {
      return;
    }
    if (state.current) {
      const workflowId = state.current;
      const project_state = state.projects[project];
      let workflow = project_state.workflows[workflowId];
      if (!workflow) {
        workflow = { ...workflowsInitialState.workflows[workflowId] };
      }
      setParam(kWorkflowParam, workflowId);
      if (workflow.targetDate) setParam(kDateParam, workflow.targetDate);
      setParam(kSelectedSegmentParam, workflow.segmentId);
      let fmtedRouteSelection = workflow.routeSelection;
      if (
        fmtedRouteSelection &&
        !routeSelectionIsSavedRoute(fmtedRouteSelection)
      ) {
        fmtedRouteSelection = fmtedRouteSelection.join(',');
      }
      setParam(kRouteSelectedParam, fmtedRouteSelection);
      if (project_state.mapCenter) {
        setParam(kMapLatParam, Number(project_state.mapCenter.lat).toFixed(4));
        setParam(kMapLngParam, Number(project_state.mapCenter.lng).toFixed(4));
      }
      if (project_state.mapZoom) {
        setParam(kMapZoomParam, project_state.mapZoom);
      }
      if (workflow.typicalMonth) {
        setParam(kTypicalMonthParam, workflow.typicalMonth);
      }
      if (workflow.comparison) {
        setParam(kComparisonParam, workflow.comparison);
      }
      if (workflow.slowdownThreshold !== null) {
        setParam(kSlowdownThresholdParam, workflow.slowdownThreshold);
      }
      setParam(kMaximizeParam, workflow.maximize);
      // workflow-specific settings
      if (workflow.binSize) {
        setParam(kBinSizeParam, workflow.binSize);
      }
      if (workflow.capacityThreshold) {
        setParam(kCapacityThresholdParam, workflow.capacityThreshold);
      }
      if (workflow.capacityLanes) {
        setParam(kCapacityLanesParam, workflow.capacityLanes);
      }
      if (workflow.vizMode) {
        setParam(kVizModeParam, workflow.vizMode);
      }
      if (workflow.timelineMetric) {
        setParam(kTimelineMetricParam, workflow.timelineMetric);
      }
    }
  }
  replaceOrPushURLParam(searchParams, [
    kWorkflowParam,
    kDateParam,
    kComparisonParam,
    kProjectParam,
  ]);
}

export const getProjectState = (state, updateState = false) => {
  if (!state.project_slug) return undefined;
  const project = state.projects[state.project_slug];
  if (!project) {
    if (!updateState) {
      return undefined;
    } else {
      state.projects[state.project_slug] = structuredClone(
        workflowsInitialState,
      );
      return getProjectState(state);
    }
  }
  return project;
};

export const getSpecificWorkflowState = (
  state,
  workflow,
  updateState = false,
) => {
  const project = getProjectState(state, updateState);
  if (!project) {
    return undefined;
  }
  if (project.workflows[workflow] === undefined && updateState) {
    project.workflows[workflow] = {
      ...workflowsInitialState.workflows[workflow],
    };
  }
  return project.workflows[workflow];
};

export const getWorkflowState = (state, updateState = false) => {
  const project = getProjectState(state, updateState);
  if (!project) {
    return undefined;
  }
  if (project.workflows[state.current] === undefined && updateState) {
    project.workflows[state.current] = {
      ...workflowsInitialState.workflows[state.current],
    };
  }
  return project.workflows[state.current];
};

export const workflowSlice = createSlice({
  name: 'state',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetWorkflowState: (state) => {
      state.current = kDefaultWorkflow;
      state.projects = initialState.projects;
      state.project_slug = undefined;
      resetURLParams();
    },
    setProjectSlug: (state, action) => {
      const projectSlug = action.payload;
      console.log(`Setting project to ${projectSlug}`);
      state.project_slug = projectSlug;
      if (!state.projects[state.project_slug]) {
        state.projects[state.project_slug] = { ...workflowsInitialState };
      }
      setUrlFromState(state, true);
    },
    setWorkflow: (state, action) => {
      const workflowId = action.payload;
      const previousWorkflow = state.current;
      console.assert(WORKFLOWS_ALL_ENABLED.includes(workflowId));
      console.log(`setWorkflow ${workflowId}`);
      state.current = workflowId;
      getWorkflowState(state, true);
      const workflowInfo = workflowLookup[workflowId];
      if (workflowInfo) {
        workflowInfo.switchToState(previousWorkflow, state);
      }
      setUrlFromState(state, true);
    },
    setTargetDate: (state, action) => {
      if (state.current) {
        getWorkflowState(state, true).targetDate = action.payload;
        setUrlFromState(state);
      }
    },
    setTypicalMonth: (state, action) => {
      if (state.current) {
        getWorkflowState(state, true).typicalMonth = action.payload;
        setUrlFromState(state);
      }
    },
    setComparison: (state, action) => {
      if (state.current) {
        getWorkflowState(state, true).comparison = action.payload;
        setUrlFromState(state);
      }
    },
    setSelectedSegmentId: (state, action) => {
      if (state.current) {
        getWorkflowState(state, true).segmentId = action.payload;
        setUrlFromState(state);
      }
    },
    setRouteSelection: (state, action) => {
      if (state.current) {
        const substate = getWorkflowState(state, true);
        if (!substate.routeSelectionHistory) {
          substate.routeSelectionHistory = [undefined];
        }
        // Push old selection history
        substate.routeSelectionHistory.push(substate.routeSelection);
        if (substate.routeSelectionHistory.length > kMaxRouteSelectionHistory) {
          // Limit the route selection undo history
          substate.routeSelectionHistory = substate.routeSelectionHistory.slice(
            substate.routeSelectionHistory.length - kMaxRouteSelectionHistory,
          );
        }
        substate.routeSelection = action.payload;

        setUrlFromState(state);
      }
    },
    undoRouteSelection: (state) => {
      if (state.current) {
        const substate = getWorkflowState(state, true);
        let selection = substate.routeSelectionHistory.pop();
        if ((selection || []).length > 0) {
          if (!routeSelectionIsSavedRoute(selection)) {
            selection = [...selection];
          }
        } else {
          selection = undefined;
        }
        substate.routeSelection = selection;
        if (selection && !routeSelectionIsSavedRoute(selection)) {
          substate.segmentId = selection[selection.length - 1];
        } else {
          substate.segmentId = undefined;
        }
        setUrlFromState(state);
      }
    },
    setMapCenter: (state, action) => {
      if (state.current) {
        const { lat, lng } = action.payload;
        // console.debug(`Setting map center ${lat} ${lng}`);
        getProjectState(state, true).mapCenter = { lat, lng };
        setUrlFromState(state);
      }
    },
    setMapZoom: (state, action) => {
      if (state.current) {
        getProjectState(state, true).mapZoom = action.payload;
        setUrlFromState(state);
      }
    },
    setSlowdownThreshold: (state, action) => {
      if (state.current) {
        getWorkflowState(state, true).slowdownThreshold = action.payload;
        setUrlFromState(state);
      }
    },
    setMaximize: (state, action) => {
      console.assert(
        [MAXIMIZE_MAP, MAXIMIZE_DATA, null].includes(action.payload),
      );
      if (state.current) {
        getWorkflowState(state, true).maximize = action.payload;
        setUrlFromState(state);
      }
    },
    setMapStyle: (state, action) => {
      console.assert(action.payload === null || MapStyle[action.payload]);
      if (state.current) {
        getProjectState(state, true).mapStyle = action.payload;
      }
    },
    setBinSize: (state, action) => {
      console.assert([BIN_15_MINS, BIN_60_MINS].includes(action.payload));
      if (state.current === WorkflowType.Plan) {
        getWorkflowState(state, true).binSize = action.payload;
        setUrlFromState(state);
      }
    },
    setCapacityThreshold: (state, action) => {
      if (state.current === WorkflowType.Plan) {
        const newValue = Math.abs(
          Number(action.payload) || kDefaultCapacityThreshold,
        );
        console.log(`setCapacityThreshold: ${newValue}`);
        getWorkflowState(state, true).capacityThreshold = newValue;
        setUrlFromState(state);
      }
    },
    setCapacityLanes: (state, action) => {
      if (state.current === WorkflowType.Plan) {
        const newValue = Math.abs(
          Number(action.payload) || kDefaultCapacityLanes,
        );
        console.log(`setCapacityLanes: ${newValue}`);
        getWorkflowState(state, true).capacityLanes = newValue;
        setUrlFromState(state);
      }
    },
    setVizMode: (state, action) => {
      console.assert([VIZ_MODE_TABLE, VIZ_MODE_CHART].includes(action.payload));
      if (state.current === WorkflowType.Plan) {
        getWorkflowState(state, true).vizMode = action.payload;
        setUrlFromState(state);
      }
    },
    setTimeOfDay: (state, action) => {
      console.assert(action.payload >= -3 * 60);
      console.assert(action.payload <= (24 + 3) * 60);
      if (WorkflowType.isTimelineIsh(state.current)) {
        getWorkflowState(state, true).timeOfDay = action.payload;
      }
    },
    setTimelineMetric: (state, action) => {
      if (WorkflowType.isTimelineIsh(state.current)) {
        getWorkflowState(state, true).timelineMetric = action.payload;
        setUrlFromState(state);
      }
    },
    setWorkflowTravelTimeToolbarUIMode: (state, action) => {
      getWorkflowState(state, true).workflowTravelTimeToolbarUIMode =
        action.payload;
    },
    setShowIncidents: (state, action) => {
      if (WorkflowType.isTimelineIsh(state.current)) {
        getWorkflowState(state, true).showIncidents = action.payload;
      }
    },
    setStateFromSearchParams: (state, action) => {
      const searchParams = new URLSearchParams(action.payload);
      console.log(`################# setStateFromSearchParams ${searchParams}`);
      const projectId = searchParams.get(kProjectParam);
      if (projectId) {
        state.project_slug = projectId;
      }
      const workflowId = searchParams.get(kWorkflowParam);
      if (workflowId) {
        state.current = workflowId;

        if (searchParams.has(kMapLatParam) && searchParams.has(kMapLatParam)) {
          getProjectState(state, true).mapCenter = {
            lat: Number(searchParams.get(kMapLatParam)),
            lng: Number(searchParams.get(kMapLngParam)),
          };
        }

        if (searchParams.has(kMapZoomParam)) {
          getProjectState(state, true).mapZoom = Number(
            searchParams.get(kMapZoomParam),
          );
        }
        if (searchParams.has(kDateParam)) {
          getWorkflowState(state, true).targetDate =
            searchParams.get(kDateParam);
        }
        if (searchParams.has(kTypicalMonthParam)) {
          if (!searchParams.has(kComparisonParam)) {
            getWorkflowState(state, true).comparison =
              searchParams.get(kTypicalMonthParam);
          }
        }
        if (searchParams.has(kComparisonParam)) {
          getWorkflowState(state, true).comparison =
            searchParams.get(kComparisonParam);
        }
        if (searchParams.has(kSelectedSegmentParam)) {
          getWorkflowState(state, true).segmentId = searchParams.get(
            kSelectedSegmentParam,
          );
        }
        if (searchParams.has(kRouteSelectedParam)) {
          let route: any = searchParams.get(kRouteSelectedParam);
          // We can tell if this contains a segment ID vs a route ID because of _s, which are always in segment IDs but never in route hash Ids
          if (route.includes('_')) {
            route = route.split(',');
          }
          getWorkflowState(state, true).routeSelection = route;
        }
        if (searchParams.has(kSlowdownThresholdParam)) {
          getWorkflowState(state, true).slowdownThreshold = Number(
            searchParams.get(kSlowdownThresholdParam),
          );
        }
        if (searchParams.has(kMaximizeParam)) {
          getWorkflowState(state, true).maximize =
            searchParams.get(kMaximizeParam);
        }
        if (workflowId === WorkflowType.Plan) {
          if (searchParams.has(kBinSizeParam)) {
            getProjectState(state, true).workflows[WorkflowType.Plan].binSize =
              searchParams.get(kBinSizeParam);
          }
          if (searchParams.has(kCapacityThresholdParam)) {
            getProjectState(state, true).workflows[
              WorkflowType.Plan
            ].capacityThreshold = Number(
              searchParams.get(kCapacityThresholdParam),
            );
          }
          if (searchParams.has(kCapacityLanesParam)) {
            getProjectState(state, true).workflows[
              WorkflowType.Plan
            ].capacityLanes = Number(searchParams.get(kCapacityLanesParam));
          }
          if (searchParams.has(kVizModeParam)) {
            getProjectState(state, true).workflows[WorkflowType.Plan].vizMode =
              searchParams.get(kVizModeParam);
          }
        }
        if (
          WorkflowType.isTimelineIsh(
            // @ts-ignore
            WorkflowType[workflowId as keyof typeof WorkflowType],
          )
        ) {
          if (searchParams.has(kTimelineMetricParam)) {
            getProjectState(state, true).workflows[
              WorkflowType.Timeline
            ].timelineMetric = searchParams.get(kTimelineMetricParam);
          }
        }
        setUrlFromState(state);
      }
    },
  },
});

const { actions, reducer } = workflowSlice;
export const {
  resetWorkflowState,
  setProjectSlug,
  setWorkflow,
  setTargetDate,
  setTypicalMonth,
  setComparison,
  setSelectedSegmentId,
  setRouteSelection,
  undoRouteSelection,
  setMapCenter,
  setMapZoom,
  setSlowdownThreshold,
  setMaximize,
  setMapStyle,
  setBinSize,
  setCapacityThreshold,
  setCapacityLanes,
  setStateFromSearchParams,
  setVizMode,
  setTimeOfDay,
  setTimelineMetric,
  setShowIncidents,
  setWorkflowTravelTimeToolbarUIMode,
} = actions;
export default reducer;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectTargetDate = (rootState) => {
  const { workflow } = rootState;
  return workflow.current && getWorkflowState(workflow)?.targetDate;
};

export const selectTypicalMonth = (rootState) => {
  const { workflow } = rootState;
  return workflow.current && getWorkflowState(workflow)?.typicalMonth;
};

export const selectComparison = (rootState) => {
  const { workflow } = rootState;
  return workflow.current && getWorkflowState(workflow)?.comparison;
};

export const selectWorkflow = (rootState) => {
  const { workflow } = rootState;
  return workflow.current;
};

export const selectProject = (rootState) => {
  const { workflow } = rootState;
  return workflow.project_slug;
};

export const selectCurrentProjectInfo = (rootState) => {
  return getProjectInfoForSlug(
    selectUserState(rootState),
    selectProject(rootState),
  );
};

export const selectShortestAvailableBin = (rootState) => {
  const projectInfo = selectCurrentProjectInfo(rootState);
  if (projectInfo) {
    return Math.min(...projectInfo.available_bins);
  } else {
    return undefined;
  }
};

export const selectSegmentId = (rootState) => {
  const { workflow } = rootState;
  return workflow.current && getWorkflowState(workflow)?.segmentId;
};

export const selectRouteSelection = (rootState) => {
  const { workflow } = rootState;
  return workflow.current && getWorkflowState(workflow)?.routeSelection;
};

export const selectRouteSelectionOrSegmentIdRoute = (rootState) => {
  const { workflow } = rootState;
  if (!workflow.current) {
    return undefined;
  }
  if (getWorkflowState(workflow)?.routeSelection) {
    return getWorkflowState(workflow)?.routeSelection;
  }
  if (getWorkflowState(workflow)?.segmentId) {
    return [getWorkflowState(workflow)?.segmentId];
  }
  return undefined;
};

export const selectMapCenter = (rootState) => {
  const { workflow } = rootState;
  return workflow.current && getProjectState(workflow)?.mapCenter;
};

export const selectMapZoom = (rootState) => {
  const { workflow } = rootState;
  return workflow.current && getProjectState(workflow)?.mapZoom;
};

export const selectSlowdownThreshold = (rootState) => {
  const { workflow } = rootState;
  // return workflow.current ? getWorkflowState(workflow)?.slowdownThreshold : null;
  return (
    workflow.current &&
    (getWorkflowState(workflow)?.slowdownThreshold ?? kDefaultSlowdownThreshold)
  );
};

export const selectMaximize = (rootState) => {
  const { workflow } = rootState;
  return workflow.current && getWorkflowState(workflow)?.maximize;
};

export const selectMapStyle = (rootState) => {
  const { workflow } = rootState;
  return workflow.current && getProjectState(workflow)?.mapStyle;
};

export const selectBinSize = (rootState) => {
  const { workflow } = rootState;
  return (
    workflow.current === WorkflowType.Plan &&
    getSpecificWorkflowState(workflow, WorkflowType.Plan, false)?.binSize
  );
};

export const selectCapacityThreshold = (rootState) => {
  const { workflow } = rootState;
  return (
    workflow.current === WorkflowType.Plan &&
    getSpecificWorkflowState(workflow, WorkflowType.Plan, false)
      ?.capacityThreshold
  );
};

export const selectCapacityLanes = (rootState) => {
  const { workflow } = rootState;
  return (
    workflow.current === WorkflowType.Plan &&
    getSpecificWorkflowState(workflow, WorkflowType.Plan, false)?.capacityLanes
  );
};

export const selectVizMode = (rootState) => {
  const { workflow } = rootState;
  return (
    workflow.current === WorkflowType.Plan &&
    getSpecificWorkflowState(workflow, WorkflowType.Plan, false)?.vizMode
  );
};

export const selectTimeOfDay = (rootState) => {
  const { workflow } = rootState;
  return (
    WorkflowType.isTimelineIsh(workflow.current) &&
    getSpecificWorkflowState(workflow, workflow.current, false)?.timeOfDay
  );
};

export const selectTimelineMetric = (rootState) => {
  const { workflow } = rootState;
  return (
    WorkflowType.isTimelineIsh(workflow.current) &&
    getSpecificWorkflowState(workflow, workflow.current, false)?.timelineMetric
  );
};

export const selectShowIncidents = (rootState) => {
  const { workflow } = rootState;
  return (
    WorkflowType.isTimelineIsh(workflow.current) &&
    getSpecificWorkflowState(workflow, workflow.current, false)?.showIncidents
  );
};

export function selectWorkflowTravelTimeToolbarUIMode(rootState) {
  const { workflow } = rootState;
  return getWorkflowState(workflow).workflowTravelTimeToolbarUIMode;
}
