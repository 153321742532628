import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LayerContext } from 'state/LayerContext';

import {
  selectMaximize,
  selectProject,
  selectTargetDate,
  getSpecificWorkflowState,
  getWorkflowState,
} from 'state/workflowSlice';
import { useGetDatesQuery } from 'state/apiSlice';

import {
  ChartSettingsWrapper,
  TaskBar,
  TaskBarGroup,
  TaskBarHeader,
  TaskBarSkeleton,
} from 'features/task_bar/TaskBar';
import { workflowStyles } from 'features/workspace/workspaceUtils';
import { WorkflowType } from 'appConstants';
import styles from 'features/workspace/Workspace.module.css';
import tbstyles from 'features/task_bar/Taskbar.module.css';

import { NRTDatePicker } from '../task_bar/DatePicker';
import { TimeSlider } from './TimeSlider';
import { TimelineMap } from './TimelineMap';
import { TimelineMetricsOption } from './TimelineMetricsOption';
import { RealtimeDataProvider } from './RealtimeDataProvider';
import { BookmarkToolbar } from '../bookmarks/BookmarkToolbar';
import { InfoWalkmeCrumb } from '../info_hover/InfoHover';
import { ReliesOn } from '../workflow_common/NoDataLoadedWarning';
import { WorkflowInfo } from '../workflow_common/workflowLookup';
import { TimelineMapContextProvider } from '../../state/TimelineMapContext';

export const workflowTimeline: WorkflowInfo = {
  dataAvailableReliesOn: [ReliesOn.nrtDay, ReliesOn.typicalMonth],
  switchToState: (fromWorkflow, state) => {
    // console.log('Handling switch from ', fromWorkflow, 'to', state.current, state);
    if (
      [
        WorkflowType.Timeline,
        WorkflowType.Speed,
        WorkflowType.TravelTime,
      ].includes(fromWorkflow)
    ) {
      getWorkflowState(state).targetDate = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).targetDate;
      getWorkflowState(state).comparison = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).comparison;
    }
  },
};

export function TimelineTaskBar({
  children: mapSettings,
}: {
  children: React.ReactNode;
}) {
  return (
    <TaskBarSkeleton
      upper={<BookmarkToolbar />}
      middle={null}
      lower={
        <>
          <TaskBarHeader>
            Map Settings <InfoWalkmeCrumb elId="timeline-settings" />
          </TaskBarHeader>
          <div className={tbstyles.task_bar_section_body}>{mapSettings}</div>
          <div className={tbstyles.task_bar_section_filler} />
        </>
      }
    />
  );
}

export function WorkflowTimeline() {
  const maximize = useSelector(selectMaximize);
  const project = useSelector(selectProject);

  const { map_style } = workflowStyles(maximize);

  // ======================

  const targetDate = useSelector(selectTargetDate);
  const { data: datesInfo } = useGetDatesQuery(project);
  const [layer, setLayer] = useState(null);
  const dispatch = useDispatch();

  // memoize so this doesn't change every render.
  const memoizedLayer = useMemo(() => ({ layer, setLayer }), [layer]);

  useEffect(() => {
    if (datesInfo) {
      if (targetDate) {
        const new_layer =
          datesInfo && targetDate
            ? datesInfo.find((info) => info.date === targetDate)?.layer
            : null;
        // console.log(`WorkflowSpeed set new layer ${new_layer} for targetDate ${targetDate}`);
        setLayer(new_layer);
      }
    }
  }, [datesInfo, targetDate, setLayer, dispatch]);

  // ======================

  return (
    <LayerContext.Provider value={memoizedLayer}>
      <TimelineMapContextProvider>
        <div className={styles.workspace_container}>
          <div className={styles.workspace_content}>
            <div className={map_style}>
              <TimelineMap alwaysUseHamburgerNav={false} />
            </div>
            <TimeSlider />
          </div>
          <TimelineTaskBar>
            <NRTDatePicker enableDayArrows />
            <RealtimeDataProvider />
            <TimelineMetricsOption />
          </TimelineTaskBar>
        </div>
      </TimelineMapContextProvider>
    </LayerContext.Provider>
  );
}
