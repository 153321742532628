import React from 'react';
import { useSelector } from 'react-redux';

import { WorkflowPlanning } from 'features/workflow_planning/WorkflowPlanning';
import { WorkflowSpeed } from 'features/workflow_nrt/WorkflowSpeed';

import { WorkflowType } from 'appConstants';
import { selectUser } from 'state/userSlice';
import { selectCurrentProjectInfo, selectWorkflow } from 'state/workflowSlice';
import { WorkflowTimeline } from 'features/workflow_timeline/WorkflowTimeline';
import { WorkflowTravelTime } from 'features/workflow_traveltime/WorkflowTravelTime';
import { useDispatchUserStatus } from '../../app/AppDispatcher';
import styles from './Workspace.module.css';
import {
  NoDataLoadedWarning,
  useHasNoRelevantData,
} from '../workflow_common/NoDataLoadedWarning';

export function NoProjectsPage() {
  return (
    <div className={styles.noprojects}>
      <p>You do not currently have access to any projects on this product</p>
      <p>Please contact support@streetlightdata.com</p>
    </div>
  );
}

export function Workspace() {
  const workflow = useSelector(selectWorkflow);
  const userProject = useSelector(selectCurrentProjectInfo);
  const user = useSelector(selectUser);
  useDispatchUserStatus();
  const hasNoRelevantData = useHasNoRelevantData();
  // console.log(`Login userStatus: ${userStatus}`);
  if (!userProject) {
    if (user && user.projects.length < 1) {
      return <NoProjectsPage />;
    } else {
      return null;
    }
  }
  if (hasNoRelevantData) {
    return <NoDataLoadedWarning />;
  }

  /* eslint-disable */
  switch (workflow) {
    case WorkflowType.Plan:
      return <WorkflowPlanning />;
    case WorkflowType.TravelTime:
      return <WorkflowTravelTime />;
    case WorkflowType.Speed:
      return <WorkflowSpeed />;
    case WorkflowType.Timeline:
      return <WorkflowTimeline />;
    default:
      return null;
  }
  /* eslint-enable */
}
