import React from 'react';

import { TravelTimeChart } from 'features/chart/TravelTimeChart';

import { WorkflowRecent } from 'features/workflow_nrt/WorkflowRecent';
import { RouteSegmentInfo } from 'features/task_bar/RouteSegmentInfo';
import { ExportTravelTimesButton } from 'features/workflow_traveltime/ExportTraveltimesButton';
import { WorkflowType } from '../../appConstants';
import {
  getSpecificWorkflowState,
  getWorkflowState,
} from '../../state/workflowSlice';
import { NoRouteSelectedPanel } from './NoRouteSelectedPanel';
import { ReliesOn } from '../workflow_common/NoDataLoadedWarning';
import { WorkflowInfo } from '../workflow_common/workflowLookup';

export const workflowTravelTime: WorkflowInfo = {
  dataAvailableReliesOn: [ReliesOn.nrtDay, ReliesOn.typicalMonth],
  switchToState: (fromWorkflow, state) => {
    // console.log('Handling switch from ', fromWorkflow, 'to', state.current, state);
    if (
      [
        WorkflowType.Timeline,
        WorkflowType.Speed,
        WorkflowType.TravelTime,
      ].includes(fromWorkflow)
    ) {
      getWorkflowState(state).targetDate = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).targetDate;
      getWorkflowState(state).comparison = getSpecificWorkflowState(
        state,
        fromWorkflow,
      ).comparison;
    }
  },
};

export function WorkflowTravelTime() {
  return (
    <WorkflowRecent
      chart={<TravelTimeChart />}
      graph_title="Travel Time"
      enableRouteSelection
      segmentInfoExtras={[<RouteSegmentInfo />]}
      chartSettingsExtras={<ExportTravelTimesButton />}
      unselected_chart_state_fn={NoRouteSelectedPanel}
    />
  );
}
