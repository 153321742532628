import React, { useRef, useEffect, useState, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import mapboxgl from 'mapbox-gl';
import styles from 'features/map/Map.module.css';

import {
  selectSegmentId,
  setSelectedSegmentId,
  setMapCenter,
  setMapZoom,
  setMaximize,
  selectMaximize,
  selectProject,
  setRouteSelection,
  selectRouteSelection,
  selectCurrentProjectInfo,
  selectMapCenter,
  selectMapZoom,
  selectMapStyle,
  undoRouteSelection,
} from 'state/workflowSlice';
import { useGetLayerQuery } from 'state/apiSlice';
import { selectUser, selectUserState } from 'state/userSlice';

import { LayerContext } from 'state/LayerContext';

import {
  ensureRtlLoaded,
  latLngToJsonPoint,
  launderLatLng,
} from 'features/map/mapUtils';
import { MapStyle, MAXIMIZE_MAP } from 'appConstants';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'features/map/Map.css';
import { useSegmentSelectionCrosswalk } from '../workflow_common/SegmentSelectionCrosswalk';
import {
  kSegmentLayerDefinition,
  kSegmentSourceId,
  initMap,
  mapPopSelectedSegment,
  getSelectionFromClickEvent,
  getInitialCenter,
  redrawBaseSegments,
  redrawSelectedSegment,
  showBookmarks,
  safeRemoveBookmarks,
  updateMapLocation,
  kMainVisLineOpacityExpression,
  MapLoadingSpinner,
  OutsideBboxPrompt,
  recenterMapToProjectBbox,
  kLongTouchTimeMs,
} from './mapCommon';

import { mapboxApiKey } from '../../appConstants';
import {
  selectDisplayedBookmark,
  selectInLocationEditMode,
  setDisplayedBookmark,
} from '../../state/bookmarkSlices';
import { useCallbackWithErrorHandling } from '../../app/ErrorHandling';
import { MapSettings } from './MapSettings';
import { ToggleLayerControl } from './mapControls';
import {
  routeSelectionIsSavedRoute,
  useRouteInfo,
} from '../common/useRouteInfo';

mapboxgl.accessToken = mapboxApiKey;

export function Map({
  enableRouteSelection = false,
}: {
  enableRouteSelection?: boolean;
}) {
  // https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/

  const dispatch = useDispatch();

  // useRef DOM refs and for variables accessed from map callbacks
  const refMap = useRef<mapboxgl.Map>(null); // mapboxgl.Map object
  const refMapContainer = useRef(null); // DOM node reference
  const refRouting = useRef({});
  const refTouchState = useRef(undefined);

  const [mapReady, setMapReady] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { layer } = useContext(LayerContext);
  const selectedSegmentId = useSelector(selectSegmentId);
  const selectedRoute = useSelector(selectRouteSelection);
  const maximize = useSelector(selectMaximize);
  const refMaximize = useRef(maximize);
  const project = useSelector(selectProject);
  const currentMapCenter = useSelector(selectMapCenter);
  const mapZoom = useSelector(selectMapZoom);
  const displayedBookmark = useSelector(selectDisplayedBookmark);
  const inLocationEditMode = useSelector(selectInLocationEditMode);
  const refInLocationEditMode = useRef(inLocationEditMode);
  const mapStyle = useSelector(selectMapStyle);
  const userInfo = useSelector(selectUserState);

  const {
    feature: routeFeature,
    savedRoute,
    basicRouteInfo,
  } = useRouteInfo(!enableRouteSelection);

  const refSelection = useRef({
    segment: selectedSegmentId,
    routeSelection: selectedRoute,
    routeFeature,
    savedRoute,
    basicRouteInfo,
  });

  const user = useSelector(selectUser);
  const userProject = useSelector(selectCurrentProjectInfo);

  // load segmentData for layer from REST api (or cache)
  const { currentData: layerData } = useGetLayerQuery(layer, { skip: !layer });
  useSegmentSelectionCrosswalk();

  // memoize copy of segments
  refRouting.current = useMemo(() => layerData?.routing, [layerData]);

  const carLayerVisible = true;

  ensureRtlLoaded();

  const handleSegmentClickCallback = useCallbackWithErrorHandling(
    (event, isRouteAddClick = false) => {
      console.log(`handleSegmentClickCallback`, event);
      if (refInLocationEditMode.current) {
        dispatch(setDisplayedBookmark(launderLatLng(event.lngLat)));
      } else {
        const routeSelectionSegments = routeSelectionIsSavedRoute(
          refSelection.current.routeSelection,
        )
          ? refSelection.current.savedRoute?.routeSegmentIds
          : refSelection.current.routeSelection;
        const {
          segmentId: newSelectedSegmentId,
          segment: selectedSegment,
          route,
        } = getSelectionFromClickEvent(
          event,
          refMap.current,
          refRouting,
          enableRouteSelection,
          isRouteAddClick,
          refSelection.current.segment,
          routeSelectionSegments,
        );
        if (route) {
          console.log('Setting Route selection: ', route);
          dispatch(setRouteSelection(route));
        } else if (route === null) {
          if (newSelectedSegmentId && enableRouteSelection) {
            dispatch(setRouteSelection([newSelectedSegmentId]));
          } else {
            dispatch(setRouteSelection(undefined));
          }
        }
        console.log('Selection = ', newSelectedSegmentId);
        dispatch(setSelectedSegmentId(newSelectedSegmentId));
      }
    },
  );

  const isTouchDrag = () => {
    const last = refTouchState.current?.lastCoord;
    const first = refTouchState.current?.startCoord;
    const isDrag =
      last && // Last might not be set by touchmove if the finger has been still (which would mean not a drag)
      (Math.abs(first.x - last.x) > 10 || // Was the touch started nearby == not a drag
        Math.abs(first.y - last.y) > 10);
    return isDrag;
  };

  const handleTouchTimeout = (event) => {
    console.log('Timeout event', event);
    refTouchState.current.timeout = undefined; // Clear timeout so touchend knows we're handling the touch
    refTouchState.current.isDrag = isTouchDrag();
    refTouchState.current.isLongTouch = true;
    if (!refTouchState.current.isDrag) {
      handleSegmentClickCallback(event, true);
    }
  };

  const handleTouchStart = useCallbackWithErrorHandling(
    (event: mapboxgl.MapTouchEvent) => {
      // Only handling single touch
      console.log('Touch start', event);
      if (event.originalEvent.touches.length === 1) {
        refTouchState.current = {
          startTime: Date.now(),
          startCoord: event.point,
          type: event.type,
          // Here' we're queuing a timeout event to handle long touches before they're finished
          // This means the user gets visual feedback of route building after kLongTouchTimeMs rather than
          // After they lift their finger (not knowing if they've held for long enough)
          timeout: setTimeout(handleTouchTimeout, kLongTouchTimeMs, event),
        };
      }
    },
  );

  const handleTouchMove = useCallbackWithErrorHandling(
    (event: mapboxgl.MapTouchEvent) => {
      // Keep track of if the user is moving their touch, and if so, how far
      console.log(event.type, event);
      if (event.originalEvent.touches.length === 1) {
        refTouchState.current.lastCoord = event.point;
      } else if (refTouchState.current.timeout) {
        // If we suddenly move to two+ touches, cancel the timeout
        clearTimeout(refTouchState.current.timeout);
      }
    },
  );

  const handleClick = useCallbackWithErrorHandling(
    (event: mapboxgl.MapMouseEvent) => {
      // console.log(`handleClick`, event, refTouchState.current);
      const isDrag = isTouchDrag();
      if (refTouchState.current) {
        if (refTouchState.current.timeout) {
          // We know that if timeout is defined, we have yet to trigger the timeout handler and therefore it is not a long press
          console.log('Short touch, cancelling timeout');
          clearTimeout(refTouchState.current.timeout); // Cancel the timeout as we know it isn't wanted now
          refTouchState.current.timeout = undefined;
          refTouchState.current.isLongTouch = false;
        } else {
          // This touch has already been handled by handleTouchTimeout so there is no need to do anything with the touch
          console.log('Long touch, already handled, ignore end');
        }
      }
      if (!isDrag) {
        handleSegmentClickCallback(event, event.originalEvent.ctrlKey);
      }
      refTouchState.current = undefined;
    },
  );

  const handleContextMenu = useCallbackWithErrorHandling((e) => {
    // Supress context menu in the case of a long touch
    if (refTouchState.current && refTouchState.current.isLongTouch) {
      e.preventDefault();
    }
  });

  function handleKeyStroke(event) {
    // On Ctrl-z, undo the last route selection action
    const code = event.which || event.keyCode;
    const charCode = String.fromCharCode(code).toLowerCase();
    if (
      enableRouteSelection &&
      (event.ctrlKey || event.metaKey) &&
      charCode === 'z'
    ) {
      dispatch(undoRouteSelection());
    }
  }

  const handleMapMoveCallback = useCallbackWithErrorHandling(() => {
    const center = {
      lng: refMap.current.getCenter().lng.toFixed(4),
      lat: refMap.current.getCenter().lat.toFixed(4),
    };
    const newZoom = refMap.current.getZoom().toFixed(2);
    dispatch(setMapZoom(newZoom));
    dispatch(setMapCenter(center));
  });

  function handleMapLoadCallback() {
    setMapReady(true);
  }

  const handleChartShowHideClickCallback = () => {
    dispatch(setMaximize(refMaximize.current ? null : MAXIMIZE_MAP));
  };

  function dispatchSelectionRedraw(force = false) {
    if (refMap.current) {
      if (!carLayerVisible) {
        mapPopSelectedSegment(refMap.current);
      } else {
        redrawSelectedSegment(
          mapReady,
          refMap.current,
          refSelection.current.segment,
          refSelection.current.routeSelection,
          refSelection.current.routeFeature,
          refSelection.current.basicRouteInfo,
          force,
        );
      }
    }
  }

  const handleSourceDataEvent = useCallbackWithErrorHandling(
    (e: mapboxgl.MapSourceDataEvent) => {
      // Given we now rely on the segments being loaded via tiles to render
      // Selected and routes, we have to listen to new tiles being rendered
      // In case the selected segments or any of the route segments are suddenly in view
      if (e.sourceId === kSegmentSourceId) {
        dispatchSelectionRedraw();
      }
    },
  );

  const handleMapSettingsClickCallback = (e) => {
    setSettingsOpen(true);
  };

  useEffect(() => {
    // Wipe old map
    if (refMap.current) {
      refMap.current.remove();
      refMap.current = undefined;
      setMapReady(false);
    }

    const { center, zoom } = getInitialCenter(userProject);
    if (!center) {
      // Project defaults not yet loaded, skipping initialisation of map
      return;
    }

    // Init new map
    initMap(
      refMap,
      refMapContainer,
      center,
      zoom,
      mapStyle,
      userInfo.has_hidden_features,
      handleMapLoadCallback,
      handleMapMoveCallback,
      handleClick,
      handleMapSettingsClickCallback,
      dispatch,
      userProject,
    );
    refMap.current.on('touchstart', handleTouchStart);
    refMap.current.on('touchend', handleTouchMove);
    refMap.current.on('touchmove', handleTouchMove);
    refMap.current.on('contextmenu', handleContextMenu);
    refMap.current.addControl(
      new ToggleLayerControl(
        'chart-showhide',
        'Hide/show chart',
        true,
        handleChartShowHideClickCallback,
      ),
    );
    refMap.current.on('sourcedata', handleSourceDataEvent);
  }, [userProject, mapStyle]); // eslint-disable-line react-hooks/exhaustive-deps

  // redrawSegments
  useEffect(
    () => {
      // console.log(`============= useeffect redrawSegments (mapReady: ${mapReady} layerData ${refSegments.current.length}`);
      const layerDefinition = { ...kSegmentLayerDefinition };
      layerDefinition.paint = { ...layerDefinition.paint };
      layerDefinition.paint['line-opacity'] = kMainVisLineOpacityExpression(
        { [MapStyle.Dark]: 0.7, [MapStyle.Sat]: 1.0 }[mapStyle] || 0.7,
      );
      redrawBaseSegments(
        mapReady,
        refMap.current,
        layerDefinition,
        layer ? `server/tiles/${layer}` : undefined,
      );
      // Redraw selected segments on layer change!
      dispatchSelectionRedraw(true);
    },
    // note - we specify layerData as dependency because change to mutable refSegments doesn't rerender
    // we don't need dependency on selectedSegmentId even though we draw the selected statement
    // because (besides initial render) it only erasing/drawing it is handled by handleSegmentClickCallback
    [mapReady, layerData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    refSelection.current.segment = selectedSegmentId;
    refSelection.current.routeSelection = selectedRoute;
    dispatchSelectionRedraw();
  }, [selectedSegmentId, selectedRoute]);

  useEffect(() => {
    refSelection.current.routeFeature = routeFeature;
    refSelection.current.savedRoute = savedRoute;
    refSelection.current.basicRouteInfo = basicRouteInfo;
    dispatchSelectionRedraw();
  }, [routeFeature, savedRoute, basicRouteInfo]);

  useEffect(() => {
    refMaximize.current = maximize;
    if (refMap.current) {
      refMap.current.resize();
    }
  }, [mapReady, maximize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refMap.current) {
      const { center } = getInitialCenter(userProject);
      refMap.current.jumpTo({ center });
    }
  }, [userProject]);

  useEffect(() => {
    updateMapLocation(refMap.current, mapZoom, currentMapCenter);
  }, [currentMapCenter, mapZoom]);

  useEffect(() => {
    refInLocationEditMode.current = inLocationEditMode;
    if (mapReady) {
      if (inLocationEditMode) {
        if (!displayedBookmark) {
          dispatch(setDisplayedBookmark({ ...refMap.current.getCenter() }));
        } else {
          const bookmarks = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: latLngToJsonPoint(displayedBookmark),
              },
            ],
          };
          showBookmarks(refMap.current, bookmarks);
        }
      } else {
        safeRemoveBookmarks(refMap.current);
      }
    }
  }, [mapReady, dispatch, inLocationEditMode, displayedBookmark]);

  useEffect(() => {
    // Add Key listener to the entire document to capture ctrl z events
    document.addEventListener('keyup', handleKeyStroke);
    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keyup', handleKeyStroke);
    };
  }, [handleKeyStroke]);

  return (
    <div ref={refMapContainer} className={styles.map}>
      <MapLoadingSpinner currentRefMap={refMap} mapReady={mapReady} />
      <OutsideBboxPrompt currentRefMap={refMap} />
      <MapSettings
        open={settingsOpen}
        handleVisSwitch={(visible) => setSettingsOpen(visible)}
      />
    </div>
  );
}
